<template>
  <v-container fluid class="pa-0">
    <DisponibilidadEquipo />
  </v-container>
</template>

<script>

export default {
  components: {
		DisponibilidadEquipo: () => import('@/modules/disponibilidadEquipo/views/DisponibilidadEquipo.vue')
	},
};
</script>
